<template>
    <div class="hover-green" @click="print">
        {{ $t("Management.Handout.HandoutPrint") }}
        <feather-icon
        icon="FileIcon"
        size="24"
        />
    </div>
</template>
<script>
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { BFormInput, BButton, BModal, BFormGroup} from "bootstrap-vue";
import { ProcessTypesEnum, SortTypesEnum } from "@/data/enums";
import { weightFormatter } from '@/utility/format/formatter';

export default {
    components:{BButton, BFormGroup, BModal, BFormInput},
    props:{
        process: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        SortTypesEnum,
        weightFormatter
    }),

    methods: {
        async print(){
            const translationsRoot = "Management.Handout";
            const doc = new jsPDF();

            const columnStyle_bottom = { fontSize: 11, halign: "center", lineWidth: 0.3, lineColor: (0,0,0), cellPadding: 2, cellWidth: 27};
            const columnStyleDate_bottom = { fontSize: 11, halign: "center", lineWidth: 0.3, lineColor: (0,0,0), cellPadding: 2, cellWidth: 38};
            const columnStyle_top = { fontSize: 11, halign: "center", cellPadding: 2};
            const processType = this.process.processType === ProcessTypesEnum.Leerung ? "EX" : "VA";
            const sortType = this.process.sortType === SortTypesEnum.Hopfenpellets ? "90" : this.process.sortType === SortTypesEnum.Lupulinpellets ? "45": "";
            let areas = "";
            let val = [];
           
            for (let index = 0; index < this.process.areas.length; index++) {
                const area = this.process.areas[index];
                if(val.findIndex(a => a === area.country.name) === -1){
                    val.push(area.country.name);
                }
            }

            for (let index = 0; index < val.length; index++) {
                const areaName = val[index];
                areas += areaName;
                if(val[index + 1 ]){
                    areas += ", "
                }
            }

            let topBody = [ [
                {content: this.$t(`${translationsRoot}.Hopfen`), styles: {fontStyle: "bold", cellWidth: 50}, colSpan: 2}, 
                this.process.sealDistrict ? 
                {content: this.$t(`${translationsRoot}.SealDistrict`), styles: {fontStyle: "bold", cellWidth: 50}, colSpan: 4}
                :
                {content: this.$t(`${translationsRoot}.Country`), styles: {fontStyle: "bold", cellWidth: 50}, colSpan: 4}, 
                {content: this.$t(`${translationsRoot}.Company`), styles: {fontStyle: "bold", cellWidth: 25}, colSpan: 1},
                {content: `${this.$t(`${translationsRoot}.Year`)}`, styles: {fontStyle: "bold",cellWidth: 25}, colSpan: 1}, 
            ],
            [
                {content: weightFormatter.format(this.process.rawHop), styles: {fontStyle: "normal"}, colSpan: 2}, 
                {content: this.process.sealDistrict ? this.process.sealDistrict.name :  areas, styles: {fontStyle: "normal"}, colSpan: 4}, 
                {content: this.process.shareholder, styles: {fontStyle: "normal"}, colSpan: 1},
                {content: `${this.process.harvestYear}`, colSpan: 1}, 
            ],
            [
                {content: `${processType} - ${this.process.processId}`, styles: {fontStyle: "bold", fontSize: 16}, colSpan: 2}, 
                {content: this.$t(`${translationsRoot}.Sort`), styles: {fontStyle: "bold", halign: 'left'}, colSpan: 3}, 
                {content: this.$t(`${translationsRoot}.Type`) +` ${sortType}` , styles: {fontStyle: "bold", halign: 'left'}, colSpan: 1},
                {content: this.$t(`${translationsRoot}.FillingQuantity`), styles: {fontStyle: "bold"}, colSpan: 2}
            ]];

            let firstRow = 
            [
                {content: this.process.gga.name !== "Kein" ? this.$t(`${translationsRoot}.Gga`) : "", styles: {fontStyle: "bold"}, colSpan: 2}, 
                {content: this.process.sorts[0].name, styles: {fontStyle: "normal", halign: 'left'}, colSpan: 4}, 
                {content: `${this.process.sorts[0].sortPercentage} %`, styles: {fontStyle: "normal"}, colSpan: 2}
            ];

            topBody.push(firstRow);

            for (let index = 1; index < this.process.sorts.length; index++) {
                const sort = this.process.sorts[index];

                topBody.push(
                    [
                        {content: this.process.gga.name !== "Kein" && index === 1 ? this.process.gga.name : "", colSpan: 2}, 
                        {content: sort.name, styles: {fontStyle: "normal", halign: 'left'}, colSpan: 4}, 
                        {content: `${sort.sortPercentage} %`, styles: {fontStyle: "normal"}, colSpan: 2}
                    ],
                )
            };
            topBody.push(
                    [
                        {content: this.process.sorts.length > 1 ? "" : this.process.gga.name !== "Kein" ? this.process.gga.name : "", colSpan: 2}, 
                        {content: this.$t(`${translationsRoot}.Area`), styles: {fontStyle: "bold", halign: 'left'}, colSpan: 4}, 
                        {content: this.$t(`${translationsRoot}.AreaCountry`), styles: {fontStyle: "bold", halign: 'left'}, colSpan: 2}, 
                    ],
                )
            for (let index = 0; index < this.process.areas.length; index++) {
                const area = this.process.areas[index];

                topBody.push(
                    [
                        {content: "", colSpan: 2}, 
                        {content: area.name, styles: {fontStyle: "normal", halign: 'left'}, colSpan: 4}, 
                        {content: `${area.country.name} (${area.country.short})`, styles: {fontStyle: "normal", halign: 'left'}, colSpan: 2}, 
                    ],
                )
            };
            
            autoTable(doc, {
                margin: { left: 20 },
                startY: 5,
                body: topBody,
                theme:"plain",
                columnStyles: { 0: columnStyle_top, 
                                1: columnStyle_top,
                                2: columnStyle_top,
                                3: columnStyle_top,
                                4: columnStyle_top
                            },
                didParseCell: data => {
                    if (data.cell && data.cell.section === "body") {
                        data.cell.styles.lineWidth = .3;
                        data.cell.styles.lineColor = (0,0,0);
                    }   
                }
            });
            
            const header = [[
                this.$t(`${translationsRoot}.Header.Date`), 
                this.$t(`${translationsRoot}.Header.Sealed`), 
                this.$t(`${translationsRoot}.Header.CertificateId`), 
                this.$t(`${translationsRoot}.Header.Costs`),
                this.$t(`${translationsRoot}.Header.Stock`),
                this.$t(`${translationsRoot}.Header.Comment`)
            ]];
            const body = [];
            for (let index = 0; index < 26 - (this.process.sorts.length + this.process.areas.length); index++) {
                body.push(["", "", "", "", "", ""])
            }

            autoTable(doc, {
                margin: { left: 20 },
                startY: 50 + (this.process.sorts.length * 8.5) + (this.process.areas.length * 8.5),
                head:header,
                body: body,
                theme:"plain",
                columnStyles: { 0: columnStyleDate_bottom, 
                                1: columnStyle_bottom,
                                2: columnStyle_bottom,
                                3: columnStyle_bottom,
                                4: columnStyle_bottom},
                didParseCell: data => {
                    if (data.cell && data.cell.section === "head") {
                        if(data.cell.text[0] === this.$t(`${translationsRoot}.Header.Comment`)){
                            data.cell.styles.lineWidth = 0;
                        } else{
                            data.cell.styles.lineWidth = 0.3;
                            data.cell.styles.lineColor = (0,0,0)
                        }
                    }   
                }
            });

            window.open(doc.output('bloburl'), '_blank'); 
        },

    }
}
</script>
<stlye lang="scss">
    .from-to-container {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
    }
</stlye>