<template>
    <b-modal
      v-if="certificate && processes.length > 0"
      id="select-process-modal"
      ref="modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      class="modal-dialog modal-dialog-centered"
    >
      <template #modal-title>
        {{ $t('Global.Select', { subject: $t('Management.Processes.Processes'), }) }}
      </template>



      <b-row v-for="(portion, index) in certificate.portions" :key="index">
        <b-col lg="5" md="5" sm="8">
          <b-form-group :label="index === 0 ? $t('Management.Certificates.Process'):$t('Management.Processes.Process')" label-for="process">

              <v-select
                id="processId"
                v-model="portion.processId"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Processes.Process'),
                  })
                "
                :options="processes"
                :label="'processId'"
                :key="'id'"
                :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                @option:selected="processSelected"
              >
              <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                <template #option="{ processId }">
                  <div class="d-flex">
                    {{ processId }}
                  </div>
                </template>
                <template #selected-option="{ processId }">
                  <div>{{ processId }}</div>
                </template>
              </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="5" md="5" sm="8">
          <b-form-group
            :label="$t('Management.Processes.Portion')"
            label-for="portion"
            >
            <validation-provider
              #default="{ errors }"
              :name="`${count}`"
              :rules="`weight: ${count}, ${certificate.weight || 0}`"
            >
              <div class="text-center">
                <b-form-input
                  id="portion"
                  v-model="portion.portion"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                  :placeholder="
                    $t('Global.Write', {
                      subject: $t('Management.Processes.Portion'),
                    })
                  "
                  autocomplete="off"
                  :type="'number'"
                  step="0.001"
                  @input="setCounter"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="2"
          class="text-center align-self-center d-flex"
        >
          <div v-if="certificate.processes.length > 1">
            <feather-icon
              icon="TrashIcon"
              size="25"
              class="hover-red"
              @click="removeProcess(process)"
            />
          </div>
          <div v-if="index === certificate.processes.length - 1">
            <feather-icon
              icon="PlusCircleIcon"
              size="25"
              class="hover-primary"
              @click="addProcess"
            />
          </div>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="buttons-container d-flex">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="closeModal"
          >
            {{ $t("Global.Save") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { BButton, BFormInput, BFormGroup, BRow, BCol, BModal } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
  import { required } from "@validations";
  import vSelect from "vue-select";

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BFormInput,
      BFormGroup,
      BRow,
      BCol,
      vSelect,
      BModal
    },
  
    mixins: [preventUnsavedChangesPopupLevel],
  
    props: {
      can: {
        type: Boolean,
        required: true,
      },
      certificate: {
        type: Object,
        required: true
      },
      processes: {
        type: Array,
        required: true
      }
    },
  
    data: () => ({
      required,
      selectedProcesses: [],
      count: 0,
      portionError: "",
    }),

    watch: {
        certificate: function(newValue) {
            if(!this.certificate.processes[0].processId){
                const process = this.processes.find(process => process.id === this.certificate.processes[0])
                this.certificate.processes[0] = process;
                this.certificate.portions[0] = {process: process, portion: 0};
            }
        }
    },

    methods: {

        removeProcess(processToRemove){
            this.certificate.processes.splice(
                this.certificate.processes.findIndex((process) => process.id === processToRemove.id),
                1
            );
            this.selectedProcesses.splice(
                this.selectedProcesses.findIndex((process) => process.id === processToRemove.id),
                1
            );

            if (this.certificate.processes.length === 0) {
                this.certificate.processes.push({
                    id: 0,
                    processId: "",
                });
            }
        },
        processSelected(process){
            this.selectedProcesses.push(process);

            this.certificate.processes.splice(
                this.certificate.processes.findIndex((process) => process.id === 0),
                1
            );
            this.certificate.processes.push(process);
        },

        setCounter(val){
          this.count = 0;
          this.certificate.portions.forEach(portion => {
            this.count += Number(portion.portion);
          });
          if(this.count !== this.certificate.weight){
            this.portionError = `Die Summe der Anteile (wenn angegeben) muss insgesamt ${this.certificate.weight}kg betragen.`;
          }
        },

        addProcess() {
            this.certificate.processes.push({
                id: 0,
                processId: "",
            });
            this.certificate.portions.push({
                id: 0,
                processId: "",
                portion: 0
            });
        },

        closeModal(){
            this.modalVisible = false;
        },

        async reset() {
            this.$emit("closed");
        },
    },
  };
  </script>
  <style lang="scss">
  .buttons-container {
    gap: 1rem;
  }
  #select-process-modal{
    .modal-dialog {
      max-width:850px;
      height:800px;
    }
    .card {
      box-shadow: none;
      padding: 0!important;
    }
  }
  </style>
  