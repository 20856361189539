var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.certificate && _vm.processes.length > 0)?_c('b-modal',{ref:"modal",staticClass:"modal-dialog modal-dialog-centered",attrs:{"id":"select-process-modal","centered":"","no-close-on-backdrop":"","no-close-on-esc":"","no-enforce-focus":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Global.Select', { subject: _vm.$t('Management.Processes.Processes'), }))+" ")]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"buttons-container d-flex"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("Global.Save"))+" ")])],1)]},proxy:true}],null,false,1740255823)},_vm._l((_vm.certificate.portions),function(portion,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"lg":"5","md":"5","sm":"8"}},[_c('b-form-group',{attrs:{"label":index === 0 ? _vm.$t('Management.Certificates.Process'):_vm.$t('Management.Processes.Process'),"label-for":"process"}},[_c('v-select',{key:'id',attrs:{"id":"processId","placeholder":_vm.$t('Global.Select', {
                subject: _vm.$t('Management.Processes.Process'),
              }),"options":_vm.processes,"label":'processId',"disabled":!_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Processes)},on:{"option:selected":_vm.processSelected},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var processId = ref.processId;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(processId)+" ")])]}},{key:"selected-option",fn:function(ref){
              var processId = ref.processId;
return [_c('div',[_vm._v(_vm._s(processId))])]}}],null,true),model:{value:(portion.processId),callback:function ($$v) {_vm.$set(portion, "processId", $$v)},expression:"portion.processId"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t("Global.NothingFound")))])])],1)],1),_c('b-col',{attrs:{"lg":"5","md":"5","sm":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Processes.Portion'),"label-for":"portion"}},[_c('validation-provider',{attrs:{"name":("" + _vm.count),"rules":("weight: " + _vm.count + ", " + (_vm.certificate.weight || 0))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"text-center"},[_c('b-form-input',{attrs:{"id":"portion","disabled":!_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Processes),"placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Processes.Portion'),
                }),"autocomplete":"off","type":'number',"step":"0.001"},on:{"input":_vm.setCounter},model:{value:(portion.portion),callback:function ($$v) {_vm.$set(portion, "portion", $$v)},expression:"portion.portion"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-center align-self-center d-flex",attrs:{"lg":"1","md":"1","sm":"2"}},[(_vm.certificate.processes.length > 1)?_c('div',[_c('feather-icon',{staticClass:"hover-red",attrs:{"icon":"TrashIcon","size":"25"},on:{"click":function($event){return _vm.removeProcess(_vm.process)}}})],1):_vm._e(),(index === _vm.certificate.processes.length - 1)?_c('div',[_c('feather-icon',{staticClass:"hover-primary",attrs:{"icon":"PlusCircleIcon","size":"25"},on:{"click":_vm.addProcess}})],1):_vm._e()])],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }